import React from 'react';
import { Button, Col, Container, Row } from "react-bootstrap";
import { StyledBlockHeading, StyledSectionCover } from "./SectionCover.style";
import { Link } from "react-scroll";

const SectionCover = () => (
  <StyledSectionCover id="home-section">
    <Container>
      <Row className="align-items-center">
        <Col lg={5}>
          <div className="box-shadow-content">
            <div className="block-heading-1">
              <StyledBlockHeading>
                <span className="d-inline-block mb-3">Dr Diana Urdiniceanu</span>
                <h1 className="mb-4">Pentru ca iti pasa de dintii tai</h1>
              </StyledBlockHeading>
              <p className="mb-4 about-text">
                Prin realizarea unui plan de tratament corect, ce tine cont de dorintele si nevoile pacientilor nostri,
                putem obtine zambetul de exceptie ravnit de oricine.
              </p>
              <p id="cover-contact-button">
                <Link
                  activeClass="active"
                  to="contact-section"
                  spy={true}
                  smooth={true}
                >
                  <Button className="btn btn-primary text-white py-3 px-5">Contactati-ma</Button>
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </StyledSectionCover>
);

SectionCover.propTypes = {};

SectionCover.defaultProps = {};

export default (SectionCover);
