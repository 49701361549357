import styled from 'styled-components'

export const StyledSectionProfile = styled.div`
  padding: 7em 0;
  
  .block-team-member-1 {
    padding: 30px;
    border: 1px solid #e9ecef;
    
    span {
      font-weight: normal;
    }
    
    h3 {
      font-size: 1.25rem;
    }
    
    p {
      color: #939393;
    }
    
    img {
      width: 130px
    }
    
    .block-social-1 {
      > a {
        position: relative;
        background: #51eaea;
        color: #fff;
        padding: 0.875rem;
        
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;
