import React, { useState } from 'react';
import { Formik } from "formik";
import * as yup from 'yup';
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { StyledSectionContact } from "./SectionContact.style";
import axios from "axios";
import { serverUrl } from "../../constants";

const schema = yup.object().shape({
  firstName: yup.string().required('Va rugam sa completati prenumele.'),
  lastName: yup.string().required('Va rugam sa completati numele.'),
  email: yup.string().email('Adresa email nu este corecta.').required('Va rugam sa completati adresa email.'),
  message: yup.string().required('Va rugam sa scrieti un mesaj.'),
});

const SectionContact = () => {
  const [success, setSuccessNotification] = useState(false);
  const [error, setErrorNotification] = useState(false);

  const onContactFormSubmit = data => {
    axios.post(
      `${serverUrl}/send-email`,
      data
    ).then(function (response) {
      setSuccessNotification(true);
    }).catch(function (error) {
      setErrorNotification(true);
    });
  }

  return (
    <StyledSectionContact id="contact-section" className="bg-light">
      <Container>
        <Row>
          <Col className="text-center mb-5">
            <div className="block-heading-1">
              <span>Nu uitati sa imi scrieti</span>
              <h2>Contact</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-5">
            <Formik
              validationSchema={schema}
              onSubmit={onContactFormSubmit}
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                message: '',
              }}
            >
              {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formLastName">
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.lastName && !!errors.lastName}
                        placeholder="Nume"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formFirstName">
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.firstName && !!errors.firstName}
                        placeholder="Prenume"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.email && !!errors.email}
                        placeholder="Adresa email"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="formMessage">
                      <Form.Control
                        as="textarea"
                        rows={10}
                        type="text"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.message && !!errors.message}
                        placeholder="Scrieti un mesaj"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <Row>
                    <Col md={6} className="ml-auto">
                      <div className="d-flex">
                        <Button type="submit" className="btn btn-block btn-primary text-white py-3 px-5 mr-1">
                          Trimite mesaj
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {success && (
              <Alert
                variant="success"
                className="mt-3"
                onClose={() => setSuccessNotification(false)}
                dismissible
              >
                Mesajul a fost trimis cu succes. Veti primi un raspuns in cel mai scurt timp.
              </Alert>
            )}
            {error && (
              <Alert
                variant="danger"
                className="mt-3"
                onClose={() => setErrorNotification(false)}
                dismissible
              >
                Mesajul nu a fost trimis. Va rugam sa incercati din nou.
              </Alert>
            )}
          </Col>
          <Col lg={4} className="ml-auto contact-details-box">
            <h2 className="text-black">Aveti nevoie de mai multe detalii?</h2>
            <p>Completati formularul si voi raspunde in cel mai scurt timp.<br/>
              De asemenea, ma puteti contacta pe pagina de facebook
              <a href="https://www.facebook.com/drdianaurdiniceanu" target="_blank" rel="noreferrer"> Dr Diana
                Urdiniceanu
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </StyledSectionContact>
  );
}

SectionContact.propTypes = {};

SectionContact.defaultProps = {};

export default SectionContact;
