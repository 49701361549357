import React from 'react';
import { Container } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { StyledSectionTestimonials } from "./SectionTestimonials.style";
import './SectionTestimonials.module.scss';

const reviews = [{
  key: 'alex',
  name: 'Alex Urdiniceanu',
  imageUrl: 'images/alex_urdiniceanu.jpg',
  description: 'Am avut câteva vizite la @Dr. Diana Urdiniceanu - Medic Stomatolog pentru a repara câteva carii, pentru detartraj și alte investigații. Pot sa recomand cu sinceritate și sa menționez ca nu am simțit niciun fel de durere indiferent de procedura folosita.5 stele de la mine.EXCELENT!'
}, {
  key: 'laura',
  name: 'Laura Florentina',
  imageUrl: 'images/laura_florentina.jpg',
  description: '🔝🔝 După detartrajul făcut dintii mei arată incredibil! O recomand din suflet! Mulțumesc , Dr Diana Urdiniceanu!❤'
}, {
  key: 'alexandro_emilio',
  name: 'Alexandro Nicolae',
  imageUrl: 'images/alexandro_nicolae.jpg',
  description: 'Fara durere si fara neplaceri, exact cum ar trebui sa fie orice vizita la medicul stomatolog!'
},
];

const SectionTestimonials = () => (
  <StyledSectionTestimonials id="testimonials-section">
    <Container>
      <div className="block-heading-1 text-center mb-5">
        <span>Ce spun clientii</span>
        <h2>Testimoniale</h2>
      </div>
      <div>
        <section className="gtco-testimonials">
          <OwlCarousel
            className="owl-carousel owl-carousel1 owl-theme h-100"
            loop
            center
            margin={0}
            responsiveClass
            nav={false}
            responsive={{
              0: {
                items: 1,
                nav: false
              },
              830: {
                items: 2,
                nav: false,
                loop: false
              },
              992: {
                items: 3,
                nav: true
              }
            }}
          >
            {reviews.map(review => (
              <div key={review.key} className="item h-100">
                <div className="card text-center h-100">
                  <img className="card-img-top" src={review.imageUrl} alt=""/>
                  <div className="card-body">
                    <h5>{review.name}</h5>
                    <p className="card-text">“ {review.description} ” </p>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </section>
      </div>
    </Container>
  </StyledSectionTestimonials>
);

SectionTestimonials.propTypes = {};

SectionTestimonials.defaultProps = {};

export default SectionTestimonials;
