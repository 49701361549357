import styled from 'styled-components';

export const StyledSectionTestimonials = styled.div`
  padding: 7em 0;
  background: aliceblue;
  
  .owl-stage {
    display: flex;
  }

  .gtco-testimonials {
    position: relative;
    margin-top: 30px;
    
    h2 {
      font-size: 30px;
      text-align: center;
      color: #333333;
      margin-bottom: 50px;
    }
    
    .owl-stage-outer {
      padding: 30px 0;
    }
    
    .owl-nav {
      display: none;
    }
    
    .owl-dots {
      text-align: center;
      
      span {
        position: relative;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: block;
        background: #fff;
        border: 2px solid #01b0f8;
        margin: 0 5px;
      }
      
      .active {
        box-shadow: none;
        
        span {
          background: #01b0f8;
          box-shadow: none;
          height: 12px;
          width: 12px;
          margin-bottom: -1px;
        }
      }
    }
    
    .card {
      background: #fff;
      box-shadow: 0 8px 30px -7px #c9dff0;
      margin: 0 20px;
      padding: 0 10px;
      border-radius: 20px;
      border: 0;
      
      .card-img-top {
        max-width: 100px;
        border-radius: 50%;
        margin: 15px auto 0;
        box-shadow: 0 8px 20px -4px #95abbb;
        width: 100px;
        height: 100px;
      }
      
      h5 {
        color: #01b0f8;
        font-size: 21px;
        line-height: 1.3;
        
        span {
          font-size: 18px;
          color: #666666;
        }
      }
      
      p {
        font-size: 18px;
        color: #555;
        padding-bottom: 15px;
      }
    }
    
    .active {
      opacity: 0.5;
      transition: all 0.3s;
    }
    
    .center {
      opacity: 1;
      
      h5 {
        font-size: 24px;
        
        span {
          font-size: 20px;
        }
      }
      
      .card-img-top {
        max-width: 100%;
        height: 120px;
        width: 120px;
      }
    }
  }
  @media (max-width: 767px) {
    .gtco-testimonials {
      margin-top: 20px;
    }
  }
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    outline: 0;
  }
`;
