import React, { useEffect, useState } from 'react';
import axios from "axios";
import moment from 'moment';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { StyledSectionBlog } from "./SectionBlog.style";
import { serverUrl } from "../../constants";

const SectionBlog = () => {
  const [posts, setPosts] = useState([]);
  const [readMore, setReadMore] = useState('');
  const [postsNumber, setPostsNumber] = useState(2);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${serverUrl}/posts`,
      );
      setPosts(result?.data?.posts?.data);
    };
    fetchData();
  }, []);
  return (
    <StyledSectionBlog id="blog-section">
      <Container>
        <Row>
          <Col className="text-center mb-5">
            <div className="block-heading-1">
              <span>Ultimele postari</span>
              <h2>Blog</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div>
              <a href="#" className="mb-4 d-block">
                <img src="images/img-video-1.jpg" alt="a blog thumbnail" className="img-fluid rounded"/>
              </a>
              <h2>
                <a href="#">Zambeste!</a>
              </h2>
              <p className="text-muted mb-3 text-uppercase small">
                <span className="mr-2">January 18, 2019</span>
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat et suscipit iste libero neque. Vitae
                quidem ducimus voluptatibus nemo cum odio ab enim nisi, itaque, libero fuga veritatis culpa quis!
              </p>
            </div>
            {readMore !== 'main-post' &&
            <a
              className="read-more-button btn pl-0 mb-3"
              onClick={() => setReadMore('main-post')}
            >
              Citeste mai departe
            </a>}
          </Col>
          <Col lg={6} className="other-blog-posts">
            {posts.filter((post, idx) => !!post.message && idx < postsNumber).map(post => {
              const messageArr = post.message.split('\n');
              const messageParagraphs = [];
              messageArr.filter((message, idx) => idx > 0).forEach((msg, msgIdx) => {
                messageParagraphs.push(
                  <p key={`${post.id}-msg-${msgIdx}`} className="text-muted">
                    <span className="mr-2">{msg}</span>
                  </p>
                );
              });
              return (
                <div key={post.id} className="post-entry mb-4">
                  <h2><a href="#">{messageArr[0]}</a></h2>
                  {messageParagraphs}
                  <p className="text-muted text-uppercase small">
                    <span className="mr-2">{moment(post.created_time).format('DD MMMM YYYY')}</span>
                  </p>
                </div>
              );
            })}
            {postsNumber < posts.length - 1 &&
            <a
              className="btn load-more-button w-100 text-center"
              onClick={() => setPostsNumber(postsNumber + 1)}
            >
              Mai multe postari
            </a>}
          </Col>
        </Row>
      </Container>
      {readMore && (
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show
          onHide={() => setReadMore('')}
        >
          <Modal.Header closeButton/>
          <Modal.Body>
            <h4>Zambeste!</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat et suscipit iste libero neque. Vitae
              quidem ducimus voluptatibus nemo cum odio ab enim nisi, itaque, libero fuga veritatis culpa quis!
            </p>
            <p className="read-more"> Folosirea unei periute de dinti prea dura sau aplicarea unei forte excesive in
              timpul periajului pot afecta atat dintele cat si gingia din jurul acestuia. La nivelul dintelui pot
              aparea leziuni de abrazie (ca niste scobituri la baza dintelui) si gingia se poate retrage, lasand
              descoperita radacina dintelui. Ambele efecte pot provoca hipersensibilitate dentara, in special la
              rece.​
              ​ ​ Una din metodele prin care puteti verifica daca si dumneavoastra aveti aceste obiceiuri vicioase
              este sa verificati periuta de dinti. Daca aceasta are o vechime mai mica de 3 luni si are perii deja
              uzati sau decolorati inseamna ca fortele aplicate in timpul periajului sunt mult prea mari.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setReadMore('')}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </StyledSectionBlog>
  );
};

SectionBlog.propTypes = {};

SectionBlog.defaultProps = {};

export default SectionBlog;
