import styled from 'styled-components';

export const StyledSectionCover = styled.div`
  height: 100vh;
  min-height: 700px;
  background-image: url('images/person-transparent-2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
  
  .row {
    height: 100vh;
    min-height: 700px;
  }
  
  p {
      font-size: 18px;
      color: #999999;
      max-width: 400px;
  }
`;

export const StyledBlockHeading = styled.div`
  span {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .1em;
    font-weight: bold;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #51eaea;
    line-height: 1;
  }
`;