import styled from 'styled-components';

export const StyledSectionBlog = styled.section`
  padding: 7em 0;
  
  p {
    color: #939393;
  }
  
  .read-more-button,
   .load-more-button {
    color: #51eaea;
  }
  
  .post-entry {
    background: #fff;
    padding: 30px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    transition: .3s all ease;
    
    h2 {
      font-size: 20px;
    }
  }
`;
