import React from 'react';
import { StyledSectionProfile } from "./SectionProfile.style";
import { Col, Container, Row } from "react-bootstrap";

const SectionProfile = () => (
  <StyledSectionProfile id="profile-section">
    <Container>
      <Row className="mb-5">
        <Col>
          <div className="block-heading-1 text-center">
            <span>Diana Urdiniceanu</span>
            <h2>Doctorul tau</h2>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={4} md={6} className="mb-4 mb-lg-0">
          <div className="block-team-member-1 block-heading-1 text-center rounded">
            <figure>
              <img src="images/poza_profil.jpg" alt="profile pic" className="img-fluid rounded-circle"/>
            </figure>
            <h3 className="">Dr Diana Urdiniceanu</h3>
            <span className="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12">Medic stomatolog</span>
            <span className="d-block font-gray-5 letter-spacing-1 text-uppercase font-size-12 mb-3">Medic rezident - Protetica Dentara</span>
            <p className="px-3 mb-3">Ofer servicii de calitate si impreuna sunt sigura ca vom reusi sa iti readucem
              zambetul pe buze</p>
            <div className="block-social-1">
              <a href="https://www.facebook.com/drdianaurdiniceanu"
                 target="_blank"
                 rel="noreferrer"
                 className="btn border-w-2 rounded primary-primary-outline--hover"
              >
                <i className="icon-facebook"/>
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </StyledSectionProfile>
);

SectionProfile.propTypes = {};

SectionProfile.defaultProps = {};

export default SectionProfile;
