import React from 'react';
import { StyledSectionServices } from "./SectionServices.style";
import { Col, Container, Row } from "react-bootstrap";

const services = [{
  key: 'profilaxie',
  icon: 'flaticon-tooth',
  title: 'Profilaxie si mentinere',
  description: 'Profilaxia este cea mai importantă etapă in ingrijirea dinților, iar vizitele regulate la stomatolog te pot scuti de foarte multe probleme.'
}, {
  key: 'extractii',
  icon: 'flaticon-dentist',
  title: 'Extractii dentare',
  description: 'Dintele nu mai poate fi reparat? Maseaua de minte e pozitionatat gresit sau nu are spatiu de dezvoltare? Ofer servicii de extractii dentare.'
}, {
  key: 'obturatii',
  icon: 'flaticon-dental-care',
  title: 'Obturatii fizionomice',
  description: 'Cele mai des intalnite probleme ale pacientilor sunt cariile. In functie de gravitatea acestora, ele pot fi "reparate" prin aplicarea unor plombe.'
}, {
  key: 'pediatrie',
  icon: 'flaticon-baby',
  title: 'Consultatii pentru copii',
  description: 'Cream zambete frumoase pentru oamenii de toate varstele, chiar si pentru cei mici. \n Stiai ca dintii copiilor au un rol important in dezvoltarea vorbitului?'
},
];

const SectionServices = () => (
  <StyledSectionServices id="services-section">
    <Container>
      <Row className="mb-5">
        <Col>
          <div className="block-heading-1">
            <span>O multime de optiuni</span>
            <h2>Servicii stomatologice</h2>
          </div>
        </Col>
      </Row>
      <div className="services-wrapper">
        {services.map(service => (
          <div key={service.key} lg={4} md={6} className="service-column mb-5">
            <div className="block-feature-1 pb-1 h-100">
            <span className="icon">
              <span className={service.icon}/>
            </span>
              <h2 className="text-dark">{service.title}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </StyledSectionServices>
);

SectionServices.propTypes = {};

SectionServices.defaultProps = {};

export default SectionServices;
