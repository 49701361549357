import React from 'react';
import { StyledSectionAbout } from "./SectionAbout.style";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

const abouts = [{
  key: 'experienta',
  value: '2',
  label: 'Ani experienta',
  format: '+',
}, {
  key: 'entuziasm',
  value: '200',
  label: 'Grad de entuziasm',
  format: '%',
}, {
  key: 'achievement',
  value: '200',
  label: 'Zambete fericite',
  format: '+',
}];

const SectionAbout = () => (
  <StyledSectionAbout id="about-section">
    <Container>
      <Row className="justify-content-center mb-4 block-img-video-1-wrap">
        <Col className="col-8 mb-5 player-container">
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player block-img-video-1'
              controls
              url='https://vimeo.com/45830194'
              width='100%'
              height='100%'
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            {abouts.map(about => (
              <Col key={about.key} md={6} lg={4}>
                <div className="block-counter-1 text-center">
                  <span className="number">
                    <span>{about.value}</span>{about.format}
                  </span>
                  <span className="caption">{about.label}</span>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  </StyledSectionAbout>
);

SectionAbout.propTypes = {};

SectionAbout.defaultProps = {};

export default SectionAbout;
