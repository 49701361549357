import styled from 'styled-components';

export const StyledFooter = styled.footer`
    background: #333333;
    
    h5 {
      font-size: 18px;
      color: white;
      font-weight: bold;
      margin-top: 30px;
    }
    
    p, .business-hours {
      color: #d2d1d1;
    }
    
    ul {
      list-style-type: none;
      padding-left: 0;
      line-height: 1.7;
      
      li {
        color: #d2d1d1;
      }
    }
    
    .stoma-logo-footer {
      max-width: 100%;
    }
    
    .contact-data {
      i {
        color: #d2d1d1;
        line-height: 1.5;
      }
    }
    
    #map-container {
      height: 240px;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    
    .social {
    a {
     &:hover {
      text-decoration: none;
     }
    }
      i {
        font-size: 30px;
        margin-right: 15px;
        margin-left: 20px;
        background-color: white;
        color: #182c39;
        border-radius: 51%;
        padding: 10px;
        height: 50px;
        width: 50px;
        text-align: center;
        line-height: 31px;
        text-decoration: none;
        
        &:hover {
          color: #2fe6e6;
        }
      }
    }
`;
