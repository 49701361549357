import styled from 'styled-components';

export const StyledSectionContact = styled.section`
  padding: 7em 0;
  
  .contact-details-box {
    p {
      color: #939393;
    }
  }
`;
