import styled from 'styled-components';

export const StyledSectionAbout = styled.div`
  padding: 7em 0;
  background: #51eaea;
  background: -moz-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #51eaea), color-stop(100%, #499bea));
  background: -webkit-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -o-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: -ms-linear-gradient(top, #51eaea 0%, #499bea 100%);
  background: linear-gradient(to bottom, #51eaea 0%, #499bea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51eaea', endColorstr='#499bea', GradientType=0 );
  
  .player-container {
    margin-top: -10rem;
  }
  .player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
    
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  
  .block-counter-1 {
    .number {
      color: #fff;
      font-size: 4rem;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;
      
      &:after {
        position: absolute;
        content: "";
        width: 50px;
        height: 3px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: rgba(255, 255, 255, 0.3);
      }
    }
    
    .caption {
      font-size: 14px;
      color: #fff;
      letter-spacing: .05em;
    }
    
    .number, .caption {
      display: block;
      line-height: 1;
    }
  }
`;