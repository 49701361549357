import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import Header from "./components/Header/Header";
import SectionCover from "./components/SectionCover/SectionCover";
import SectionServices from "./components/SectionServices/SectionServices";
import SectionAbout from "./components/SectionAbout/SectionAbout";
import SectionProfile from "./components/SectionProfile/SectionProfile";
import SectionTestimonials from "./components/SectionTestimonials/SectionTestimonials";
import SectionBlog from "./components/SectionBlog/SectionBlog";
import SectionContact from "./components/SectionContact/SectionContact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <Header/>
      <SectionCover/>
      <SectionServices/>
      <SectionAbout/>
      <SectionProfile/>
      <SectionTestimonials/>
      <SectionBlog/>
      <SectionContact/>
      <Footer/>
    </div>
  );
}

export default App;
