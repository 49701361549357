import React, { useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, Events } from 'react-scroll'
import './Header.module.scss';
import { StyledLogo, StyledNavbar } from "./Header.style";

const sections = [{
  label: 'Acasa',
  href: 'home-section',
}, {
  label: 'Servicii',
  href: 'services-section',
}, {
  label: 'Despre',
  href: 'about-section',
}, {
  label: 'Profil',
  href: 'profile-section',
}, {
  label: 'Testimoniale',
  href: 'testimonials-section',
}, {
  label: 'Blog',
  href: 'blog-section',
}, {
  label: 'Contact',
  href: 'contact-section',
}];

Events.scrollEvent.register('end', function (to, element) {
  console.log('end', arguments);
});

const Header = () => {
  const [currentHref, setHref] = useState('home-section');
  return (
    <StyledNavbar expand="lg" fixed="top" className="bg-white">
      <Container>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div>
            <Navbar.Brand href="#home">
              <StyledLogo
                className="stoma-logo"
                src="images/new.png"
                alt="Logo"
              />
            </Navbar.Brand>
          </div>
          <div>
            <Navbar.Toggle aria-controls="stoma-navbar"/>
            <Navbar.Collapse id="stoma-navbar">
              <Nav>
                {sections.map(section => (
                  <Link
                    key={section.href}
                    activeClass="active"
                    to={section.href}
                    spy={true}
                    smooth={true}
                    offset={-90}
                    onSetActive={section => setHref(section)}
                  >
                    <Nav.Link
                      className="mx-3 py-3"
                      href={section.href}
                    >
                      {section.label}
                    </Nav.Link>
                  </Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
        {/*<Link*/}
        {/*  activeClass="active"*/}
        {/*  to="home-section"*/}
        {/*  spy={true}*/}
        {/*  smooth={true}*/}
        {/*  offset={-90}*/}
        {/*  onSetActive={section => setHref('home-section')}*/}
        {/*>*/}
        {/*  <div className="go-to-start-btn">^</div>*/}
        {/*</Link>*/}
      </Container>
    </StyledNavbar>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
