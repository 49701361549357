import styled from 'styled-components';

export const StyledSectionServices = styled.div`
  padding: 7em 0;
  
  .services-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    
    .service-column {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
      
      p {
        color: #939393;
      }
    }
  }
  
  .block-feature-1 {
    background: #f2f2f2;
    padding: 30px 30px 30px 50px;
    position: relative;
    
    .icon {
      width: 80px;
      height: 80px;
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      left: -40px;
      top: 50%;
      border: 4px solid #fff;
      transform: translateY(-50%);
      text-align: center;
      
      background: #51eaea;
      background: -moz-linear-gradient(top, #51eaea 0%, #499bea 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #51eaea), color-stop(100%, #499bea));
      background: -webkit-linear-gradient(top, #51eaea 0%, #499bea 100%);
      background: -o-linear-gradient(top, #51eaea 0%, #499bea 100%);
      background: -ms-linear-gradient(top, #51eaea 0%, #499bea 100%);
      background: linear-gradient(to bottom, #51eaea 0%, #499bea 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#51eaea', endColorstr='#499bea', GradientType=0 );
      
      span {
        font-size: 40px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      
    }
    
    h2 {
      font-size: 18px;
    }
  }
`;

