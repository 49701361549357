import styled from "styled-components";
import { Navbar } from 'react-bootstrap';

// Styled component named StyledButton
export const StyledLogo = styled.img`
  max-height: 37px;
`;


export const StyledNavbar = styled(Navbar)`
  box-shadow: 4px 0 20px -5px rgb(0 0 0 / 20%);
  // box-shadow: ${props => window.pageYOffset > 0 ? '4px 0 20px -5px rgb(0 0 0 / 20%)' : 'none'};
  
  .navbar-nav {
    > .active {
      color: #51eaea;
      border-bottom: 2px solid #51eaea;
  
      > a {
        color: #51eaea !important;
      }
    }
  }
  
  //.go-to-start-btn {
  //  position: fixed;
  //  bottom: 1rem;
  //  right: 1rem;
  //  background: #51eaea;
  //  color: white;
  //  padding: 1rem;
  //  border-radius: 50%;
  //}
`;